import React from "react";
import Slideshow from "./SlideShow";

const About = ({id}) => {
    return(
        <div className="section-content" id={id}>
            <p style={{textAlign:"center"}}>
                Welcome to FotoMaker, we make your memories last forever.
            </p>
            <Slideshow/>
        </div>  
    );
};

export default About;