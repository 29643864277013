import React from "react";
import img2 from "../assets/Indiv2.jpg";
import img1 from "../assets/RAJ00226.jpg";
import img from "../assets/RAJ01474-Edit.jpg";
import img3 from "../assets/RAJ09211.jpg";
// import img3 from "../assets/Indiv7.jpg";
// import img4 from "../assets/DSC05695.jpg";
import img5 from "../assets/DSC02865.jpg";
// import img6 from "../assets/Indiv3.jpg";
import '../css/SlideShow.css';
import '../css/App.css';
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const images = [
    img,img1,img2,img3,img5
];

const Slideshow = ({id}) => {

  return (
      <div>
          <Fade
          style={{ maxWidth: "100%", margin: "0 auto", display: "inline-block" }}
          arrows={false}
          >
               <div className="each-fade">
                  <img
                  style={{ maxWidth: "100%" }} 
                  alt="image1"
                  src={images[4]} />
                </div>
                <div className="each-fade">
                  <img
                  style={{ maxWidth: "100%" }} 
                  alt="image1"
                  src={images[0]} />
                </div>
                <div className="each-fade">
                  <img 
                  style={{ maxWidth: "100%" }}
                  alt="image4"
                  src={images[3]} />
                </div>
                <div className="each-fade">
                  <img 
                  style={{ maxWidth: "100%" }}
                  alt="image2"
                  src={images[1]} />
                </div>
                <div className="each-fade">
                  <img 
                  style={{ maxWidth: "100%" }}
                  alt="image3"
                  src={images[2]} />
                </div>
          </Fade>
        </div>
  );
};

export default Slideshow;
