import React from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faInstagram,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import '../css/Social.css';

export default function Social({ id }) {
  return (
      <div className="section-content" id={id}>
          <Container className="social"> 
            <p className="social-container">
                <a
                href="https://www.youtube.com/c/FotomakerPhotography"
                className="youtube social"
                >
                <FontAwesomeIcon icon={faYoutube} size="2x" />
                </a>
                <a
                href="https://www.facebook.com/fotomakerphotography/"
                className="facebook social"
                >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                <a
                href="http://www.instagram.com/fotomaker_photography"
                className="instagram social"
                >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
                <a href="https://www.linkedin.com/in/fotomaker-photography-57b1b3160/" className="LinkedIn social">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
                </a>
            </p>
          </Container>
      </div>
  );
}