import React, { useState } from 'react';
import '../css/PhotoGrid.css';

const FullscreenImage = ({ src, onClose }) => {
  const [isFullscreen, setIsFullscreen] = useState(true);

  const handleImageClick = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleOverlayClick = () => {
    setIsFullscreen(false);
    onClose();
  };

  const fullscreenClass = isFullscreen ? 'fullscreen' : '';

  return (
    <div className="fullscreen-overlay" onClick={handleOverlayClick}>
      <img
        className={fullscreenClass}
        src={src}
        alt="Fullscreen"
        onClick={handleImageClick}
      />
    </div>
  );
};

export default FullscreenImage;
