import React, { useState  } from "react";
import img7 from "../assets/DSC00883.jpg";
// import img9 from "../assets/DSC05695.jpg";
// import img10 from "../assets/FOTO2867.jpg";
import img from "../assets/Indiv2.jpg";
import img1 from "../assets/Photo1.jpg";
import img2 from "../assets/Prop1.jpg";
import img3 from "../assets/DSC02865.jpg";
import img4 from "../assets/Indiv7.jpg";
import img5 from "../assets/Photo7.jpg";
import img6 from "../assets/Photo8.jpg";
import img8 from "../assets/Indiv3.jpg";
import '../css/App.css';
import '../css/PhotoGrid.css';
import FullscreenImage from "./FullScreenImage";

const Photos = ({ id }) => {
    const photos = [
        {
          url: img7,
          caption: 'Photo 7',
        },
        // {
        //   url: img9,
        //   caption: 'Photo 9',
        // },
        // {
        //   url: img10,
        //   caption: 'Photo 2',
        // },
        {
          url: img1,
          caption: 'Photo',
        },
        {
          url: img3,
          caption: 'Photo 1',
        },
        {
          url: img2,
          caption: 'Photo 2',
        },
        {
          url: img,
          caption: 'Photo 4',
        },
        {
          url: img4,
          caption: 'Photo 4',
        },
        {
          url: img5,
          caption: 'Photo 5',
        },
        {
          url: img6,
          caption: 'Photo 6',
        },
        {
          url: img8,
          caption: 'Photo 8',
        },
    ];

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (imageUrl) => {
      setSelectedImage(imageUrl);
    };

    return (
      <div className="section-content" id={id}>
          <div className="photo-grid-container">
                <div className="photo-grid">
                  {photos.map(photo => (
                    <img 
                      src={photo.url} 
                      alt={photo.caption} 
                      onClick={() => handleImageClick(photo.url)}
                    />
                  ))}
                  {selectedImage && (
                    <FullscreenImage
                      src={selectedImage}
                      onClose={() => setSelectedImage(null)}
                    />
                  )}
                </div>
              {/* </div> */}
          </div>
      </div>
        
    );
};

export default Photos;