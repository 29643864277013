import './css/App.css';
import Navbar from "./Components/Navbar";
import React, { Component} from "react";
import Photos from './Components/Photos';
import About from './Components/About';
import Social from './Components/Social';

class App extends Component {
  render() {
    return(
      <div className="App">
        <Navbar />
        {/* <img
            src={logo}
            className="nav-logo"
            alt="Logo"
        /> */}
        <About
          title="About"
          dark={false}
          id="section1"
        />
        <Photos
          title="Photos"
          dark={false}
          id="section5"
        />
        <Social
          title="Social"
          dark={false}
          id="section6"
        />
      </div>
    );
  }
}

export default App;
