import React from "react";
import logo from "../assets/FM3.png";
import { Link } from "react-scroll";
import '../css/App.css';

export default function Navbar() {
  const scrollToTop = () => {
    window.scrollTo(0,0);
  };

    return (
      <nav className="nav" id="navbar">
        <div className="nav-content">
            {/* <Link className="nav-item"
                onClick={scrollToTop}>
                  F | M
            </Link> */}
           <img
            src={logo}
            className="nav-logo"
            alt="Logo"
            onClick={scrollToTop}
            /> 
          <ul className="nav-items">
            <li className="nav-item">
              <Link
                activeClass="active"
                to="section1"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                HOME
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-item"
                activeClass="active"
                to="section2"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                STORIES
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                activeClass="active"
                to="section5"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                PHOTOS
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to="section6"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                SOCIAL
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }